import React, { VFC } from 'react';
import * as THREE from 'three';
import { Canvas } from '@react-three/fiber';
import { Background } from './Background';

export const TCanvas: VFC = () => {
	const OrthographicCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, -10, 10)

	return (
		<Canvas camera={OrthographicCamera} dpr={window.devicePixelRatio}>
			<Background />
		</Canvas>
	)
}
